.btn{
    border-radius: $brdrd;
    font-size: 14px;
    font-weight: 700;
    border: none;
    cursor: pointer;
    transition: 0.2s all linear;
    
    &_primary{
        background-color: $primary;
        color: $secondary-bg;
        &:hover {
            box-shadow: 0px 0px 8px rgba(255, 229, 0, 0.4);
        }
        &:active {
            box-shadow: none;
        }
    }

    &_inverted{
        background-color: $secondary-bg;
        color: $primary;
        &:hover {
            background-color: $primary;
            color: $secondary-bg;
        }
        &:active {
            background-color: $secondary-bg;
            color: $primary;
        }
    }

    &_bordered {
        background-color: transparent;
        border: 1px solid $primary;
        color: $primary;
    }

    &_normal-size {
        padding: 1.2rem 2rem;
    }

    &_larger-size {
        padding: 1.6rem 3.2rem;
        font-size: 16px;
    }

    &_full-width {
        width: 100%;
    }

    &_icon {
        span {
            padding-left: 24px;
            background-repeat: no-repeat;
            background-position: 0% center;
            background-size: contain;
        }
    }

    &_icon-hero {
        span{
            background-image: url(../../img/icon-register.svg);
        }
    }
    &_icon-playlist {
        span{
            background-image: url(../../img/icon-add.svg);
        }
    }

    
}
