.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    padding-left: 8rem;
    padding-right: 8rem;
    border-bottom: 1px solid #23262F;
    @media screen and (max-width: $desktop-mini){
        padding-left: 4rem;
        padding-right: 4rem;
    }
    @media screen and (max-width: $tablet){
        flex-wrap: wrap;
        border-bottom: none;
        row-gap: 4rem;
        padding-left: 1.6rem;
        padding-right: 1.6rem;
    }
}

.logo {
    height: 48px;
    &__img {
        height: 100%;
    }
}

.header-divider {
    border-left: 1px solid #353945;
    height: 4.8rem;
    @media screen and (max-width: $tablet){
        order: 2;
        width: 100%;
        height: 1px;
        flex-basis: 100%;
        position: absolute;
        left: 0;
        background: #23262F;
    }
}

.login {
    display: flex;
    gap: 8px;
    @media screen and (max-width: $tablet){
        .register {
            display: none;
        }
    }
}