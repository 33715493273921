.nav:not(.nav_footer) {
    @media screen and (max-width: $desktop-mini){
        position: absolute;
        visibility: hidden;
        opacity: 0;
        width: 0;
        height: 0;
    }
}

.nav__list {
    display: flex;
    margin: 0;
    padding-left: 0;
}

.nav__list_footer {
    justify-content: center;
    @media screen and (max-width: $tablet){
        flex-direction: column;
        text-align: center;
    }
}

.nav__item {
    @media screen and (min-width: $desktop-mini){
        &:first-of-type .nav__link {
            padding-left: 0;
        }
        &:last-of-type .nav__link {
            padding-right: 0;
            &::after{
                display: none;
            }
        }
    }

}

.nav__link {
    display: block;
    font-weight: bold;
    font-size: 14px;    
    padding: 1.6rem 2.4rem;
    position: relative;
    &::after {
        content: "";
        display: block;
        height: 20px;
        width: 1px;
        position: absolute;
        background-color: #777E90;
        top: calc(0% + 15px);
        right: 0;
        @media screen and (max-width: $desktop-mini){
            display: none;
        }
    }
    &:hover {
        color: $primary;
    }
}

.mobile-nav {
    display: none;
    background: transparent;
    border: none;
    @media screen and (max-width: $desktop-mini) {
        display: block;
    }
}