.popup-shadow {
    background: rgba(20, 20, 22, 0.8);
    backdrop-filter: blur(10px);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    z-index: 999;
}

.popup__close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    background: transparent;
    border: none;
    cursor: pointer;
}

.popup {
    width: 336px;
    position: relative;
    margin: auto;
    padding: 2.4rem 2rem 3.5rem 2rem;
    background-color: $secondary-bg;
    box-shadow: 0px 0px 20px rgba(15, 15, 15, 0.55);
    border-radius: 8px;
}

.popup__content {
    text-align: center;
}
.popup__title {
    font-size: 2.8rem;
    color: $text-title;
    font-weight: bold;
    line-height: 140%;
    margin: 0;
}

.popup__content>p {
    font-size: 1.2rem;
}
.popup__content a {
    color: #5790FF;
}


.popup__divider {
    border: none;
    border-bottom: 1px solid #353945;
    margin: 1.6rem 0;
}