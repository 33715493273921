// vars
   // color
   $main-bg: #141416;
   $secondary-bg: #23262C; 
   $primary: #FFE600;

   $text-page-title: #ffffff;
   $text-title: #E1E1E1;
   $text-main: #777E90;
   $chords-color: #E42C54;


   // media screens
   $bigscreen: 1920px;
   $desktop: 1440px;
   $desktop-mini: 1200px;
   $tablet: 768px;
   $phone: 375px;
   
   
    // sizes
    $brdrd: 90px;
    $font-family: "Inter";