.hero {
    padding-top: 2.1rem;
    padding-bottom: 6rem;
}

.hero__row {
    justify-content: space-between;
    align-items: center;
    gap: 10rem;
    &_author {
        gap: 4.8rem;
    }
    @media screen and (max-width: $desktop-mini){
        gap: 1.6rem;
    }
    @media screen and (max-width: $tablet){
        flex-wrap: wrap;
        justify-content: center;
    }
}

.hero__img img {
    border-radius: 35px;
    filter: drop-shadow(0px 0px 20px rgba(15, 15, 15, 0.55));
}

.hero__img_author {
    flex-basis: 50%;
}
.hero__img_author img{
    border-radius: 4px;
}

.hero__title {
    font-weight: bold;
    color: $text-page-title;
    font-weight: 700;
    font-size: 5.2rem;
    line-height: 140%;
    margin-bottom: 1.6rem;
}

.hero__subtitle {
    font-size: 1.6rem;
    line-height: 180%;
    margin-bottom: 4rem;
}

.hero__text {
    font-size: 1.6rem;
    line-height: 180%;
}

@media screen and (max-width: $desktop-mini){
    .hero__content {
        flex-basis: 50%;
    }
    .hero__title {
        font-size: 3.6rem;
    }
    .hero__subtitle {
        font-size: 1.4rem;
        line-height: 140%;
        margin-bottom: 3.2rem
    }
    .hero__img img {
        width: 100%;
    }
}

@media screen and (max-width: $tablet){
    .hero__content {
        flex-basis: 100%;
    }
    .btn_hero {
        width: 100%;
    }
}