.fp-sections-wrapper{
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 4rem;
}

.fp-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    grid-column-start: 1;
    @media screen and (max-width: $desktop-mini){
        grid-column-end: 3;
    }
}

.fp-section_collections {  
    grid-row-start: 1;
    grid-row-end: 3;
}

.fp-section_novelties {
    grid-row-start: 3;
    justify-content: space-between;
}

.fp-section_main {
    grid-column-start: 1;
    grid-column-end: 4;
}
.fp-section_aside {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 5;
    gap: 0;
    @media screen and (max-width: $desktop-mini){
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 4;
    }
}

.fp-section__title {
    color: $text-title;
    font-size: 1.6rem;
    font-weight: bold;
    flex-basis: 70%;
    @media screen and (max-width: $tablet){
        flex-basis: 100%;
        text-align: center;
    }
}

.fp-section_aside .fp-section__title {
    flex-basis: 50%;
    @media screen and (max-width: $tablet){
        flex-basis: 100%;
    }
}

@media screen and (max-width: $tablet){
    .fp-section__link {
        order: 3;
        flex-basis: 100%;
    }
}
