.song-modifyer {
    display: flex;
    justify-content: space-between;
    text-align: left;
    align-items: center;
}

.song-modifyer p {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 2rem;
    color: $text-page-title;
    margin: 0;
}

.song-modifyer__btn {
    width: 32px;
    height: 32px;
    background: $text-main;
    border-radius: 12px;
    border: none;
    padding: 0;
    line-height: 32px;
    cursor: pointer;
    &:first-of-type{
        margin-right: 4px;
    }
    &:last-of-type{
        margin-left: 4px;
    }
}

.song-modifyer__input {
    border: 2px solid #777E91;
    border-radius: 12px;
    background-color: transparent;
    text-align: center;
    color: $text-main;
    height: 28px;
    padding: 0;
}