@import-normalize;
@import "./variables.scss";
@import "./buttons.scss";
@import "./forms.scss";
@import "./popup.scss";
@import "./header.scss";
@import "./navigation.scss";
@import "./breadcrumbs.scss";
@import "./search.scss";
@import "./hero.scss";
@import "./fp-sections.scss";
@import "./card.scss";
@import "./new-sign.scss";
@import "./song-rating.scss";
@import "./song-modifyer.scss";
@import "./footer.scss";

html {
    font-size: 62.5%;
}

body {
    background-color: $main-bg;
    font-family: $font-family, 'Arial';
    color: $text-main
}

h1 {
    margin: 0;
}

ul li {
    list-style: none;    
}
a {
    text-decoration: none;
}

.link {
    color: $text-main;
    text-decoration: none;
}

.container {
    padding-left: 16rem;
    padding-right: 16rem;
    max-width: 1920px;
    margin: auto;
    @media screen and (max-width: $desktop-mini){
        padding-left: 4rem;
        padding-right: 4rem;
    }
    @media screen and (max-width: $tablet){
        padding-left: 1.6rem;
        padding-right: 1.6rem;
    }
}

.row {
    display: flex;
}

.chords-wrapper {
    width: 550px;
    margin: auto;
    padding-top: 4rem;
}

.chords-header {
    text-align: center;
    padding-bottom: 3.2rem;
}
.chords-header__meta .song-rating {
    justify-content: center;
    margin-bottom: 2rem;
}
.chords-header__title {
    font-size: 3.6rem;
    font-weight: bold;
    color: $text-title;
}
.chords-header__subtitle {
    font-size: 2rem;
}
.article_chords {
    background: $secondary-bg;
    padding: 2.4rem 4rem 4rem;
}

.article__content {
    font-size: 1.6rem;
}
.chord {
    color: #E42C54;
}
