.card {
    display: flex;
    flex-grow: 1;
    align-items: center;
    gap: 1.6rem;
}

.card_main {
    width: 100%;
    background: transparent;
    padding: 0;
}

.card_collection {
    flex-basis: 45%;
    padding: 1.2rem;
    background-color: #23262C;
    @media screen and (max-width: $desktop){
        flex-basis: 40%;
    }
    @media screen and (max-width: $tablet){
        flex-basis: 100%;
    }
}

.card_main, .card_novelty {
    position: relative;
}

.card_novelty {
    flex-direction: column;
}

.card__img {
    width: 80px;
    height: 80px;
    filter: drop-shadow(0px 0px 20px rgba(15, 15, 15, 0.55));
    border-radius: 4px;
}

.card_novelty .card__img {
    width: 100%;
    height: auto;
}

.card_novelty .card__content {
    text-align: center;
}

.card__content{
    flex-grow: 1;
}

.card__title {
    font-weight: bold;
    font-size: 1.6rem;
    color: $text-title;
    line-height: 2.4rem;
    margin: 0;
    margin-bottom: 0.8rem;
}

.card_collection .card__title  {
    margin-bottom: 1.2rem;
}

.card_novelty .card__title  {
    margin-bottom: 0.4rem;
}

.card__subtitle {
    font-size: 1.4rem;
    line-height: 2rem;
}

.card__icons {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    gap: 1.6rem;
}

.card__icon {
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    background-repeat: no-repeat;
    background-position: 95% center;
    &_apple {
        background-image: url(../../img/icon-apple.svg);
    }
    &_youtube {
        background-image: url(../../img/icon-youtube.svg);
    }
    &_spotify {
        background-image: url(../../img/icon-spotify.svg);
    }
}

.divider {
    margin: 1.2rem 0;
    width: 100%;
    border-radius: 1px;
    border: none;
    border-top: 1px solid #353945;
}