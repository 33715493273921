.new-sign{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 4px 8px;
    border-radius: 4px;
    border-width: 2px;
    border-style: solid;
}

.new-sign_position-absolute {
    position: absolute;
    top: 1.2rem;
    left: 1.2rem;
}

.new-sign_position-absolute-right {
    left: auto;
    right: 0;
    top: 0;
}

.new-sign_primary  {
    color:  #674FFF;
    border-color:  #674FFF;
}

.new-sign_secondary{
    color:  $text-page-title;
    border-color:  $text-page-title;
}