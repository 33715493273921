.song-rating {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
    gap: 1.6rem;
}

.song-rating__item {
    position: relative;
    padding-left: 1.6rem;
}

.song-rating__item:before {
    content: "";
    display: block;
    width: 1px;
    height: 12px;
    background-color: #353945;
    position: absolute;
    top: calc(0% + 3px);
    left: 0;
}

.song-rating__count {
    font-size: 1.6rem;
    margin-right: 4px;
}

.song-rating__icon {
    border: none;
    width: 18px;
    height: 14px;
    background-repeat: no-repeat;
    background-position: 95% center;
    background-color: transparent;
    background-size: cover;
}
.song-rating__icon_view {
    background-image: url(../../img/icon-view.svg);
}
.song-rating__icon_like {
    background-image: url(../../img/icon-like.svg);
}