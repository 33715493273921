.footer {
    margin-top: 6rem;
    border-top: 1px solid $secondary-bg;
    padding: 2.4rem 0 4rem 0;
}

.footer-main {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.6rem 0 3.6rem 0;
    gap: 4rem;
    @media screen and (max-width: $tablet){
        flex-direction: column;
        gap: 2rem;
    }
}

.footer-social {
    padding: 0;
    margin: 0;
    display: flex;
    gap: 4rem;
}

.footer-social__link {
    display: block;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.footer-social__link_instagram {
    background-image: url(../../img/social/instagram.svg);
}

.footer-social__link_tt {
    background-image: url(../../img/social/tik-tok.svg);
}

.footer-social__link_yt {
    background-image: url(../../img/social/youtube.svg);
}

.footer-social__link_tg {
    background-image: url(../../img/social/telegram.svg);
}

.footer-social__link_spot {
    background-image: url(../../img/social/spotify.svg);
}

.copyright {
    font-size: 1.4rem;
    text-align: center;
    margin: 0;
}