.search {
    @media screen and (max-width: $tablet){
        order: 2;
        flex-basis: 100%;
    }
}
.search__input {
    background: url(../../img/search-icon.svg) no-repeat;
    background-position: 95% center;
    border-radius: $brdrd;
    width: 26rem;
    padding: 12px 16px;
    color: $text-main;
    outline: none;
    border: 2px solid #353945;
    transition: width 0.5s linear;
    @media screen and (max-width: $desktop){
        width: auto;
    }
    @media screen and (max-width: $tablet){
        width: -webkit-fill-available;
    }
}
.search__input:hover {
    border-color: $text-title;
    color: $text-title;
}

.search__input:active,
.search__input:focus{
    border-color: #FFE600;
    position: absolute;
    width: 50%;
    top: 1.85rem;
    z-index: 999;
    left: 25%;
    background: #141416;
    transition: all 0.5s linear;
}
.search__input:visited {
    transition: all 0.5s linear;
}