.form {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
.form_add-chord {
    text-align: center;
}

.form__fieldset {
    border: none;
    padding: 0;
    margin: 0;
}

.form__label {
    font-size: 1.6rem;
    color: $text-title;
    font-weight: bold;
    margin-bottom: 6px;
    text-indent: 12px;
}

.form__label_checkbox {
    display: grid;
    grid-template-columns: minmax(20px, 1fr) 8fr;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    text-indent: 0;
    color: $text-main;
}

.form__input{
    width: -webkit-fill-available;
    background: transparent;
    padding: 1.2rem 1.6rem;
    border: 2px solid #353945;
    border-radius: 12px;
    color: $text-page-title;
}

.form__input-checkbox {
    position: absolute;
    visibility: hidden;
    width: 0;
    
    height: 0;
}
.form__label_checkbox span {
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: 2px solid #E1E1E1;
    border-radius: 6px;
    margin-right: 8px;
}

.form__textarea {
    box-sizing: border-box;
    width: 100%;
    height: 550px;
    border: 2px solid #353945;
    border-radius: 12px;
    background-color: transparent;
    color: $text-page-title;
    padding: 8px;
}

.form_add-chord .form__input {
    width: auto;
}